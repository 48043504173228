import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router";

import Table, {
  StyledTableCell,
  StyledTableRow,
} from "../../../Component/UI/Table/Table";
import MUIIconButton from "./../../../Component/UI/MUIIconButton/MUIIconButton";
import MUIChip from "./../../../Component/UI/MUIChip/MUIChip";
import { fetchBrands, deleteBrand } from "../../../redux/actions/brandsActions";
import { fetchPersonas } from "../../../redux/actions/personasActions";
import { fetchcoreAttributes } from "../../../redux/actions/coreAttributeActions";
import classes from "./Brand.module.css";
import { AuthContext } from "../../../context/auth-context";
import Spinner from "../../../Component/Spinner/Spinner";

const TABLE_HEADERS = [
  { id: "brand", name: "Brand", sorting: true },
  { id: "details", name: "Details", sorting: false },
  { id: "brand_category", name: "Brand Category", sorting: true },
  { id: "brand_competitor", name: "Brand Competitor", sorting: true },
  { id: "persona", name: "Persona", sorting: true },
  { id: "status", name: "Status", sorting: true },
  { id: "actions", name: "Actions", sorting: false },
];

const CHIP_COLORS = [
  "#CEB4FF80",
  "#F7C4FF80",
  "#FFBFAB80",
  "#C4FFFA80",
  "#CFCFCF80",
  "#FFDE9E80",
  "#C4FF8980",
  "#FFF27980",
];

export default function Brand({
  setUpdateModalOpen,
  setModalOpen,
  setBrandName,
  setDetails,
  setBrandCategoryName,
  setBrandCategoryDescription,
  setCompetitorOption,
  setCompetitors,
  setBrandPersona,
  setSelectBrandID,
}) {
  const [orderBy, setOrderBy] = React.useState("brand");
  const [order, setOrder] = React.useState("asc");

  const brands = useSelector((state) => state.brands);
  const personas = useSelector((state) => state.personas);
  const coreAttributes = useSelector((state) => state.coreAttributes);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const customerId = user?.customer_id || 0;

  useEffect(() => {
    if (brands?.data && brands?.data.length === 0) {
      dispatch(fetchBrands(customerId));
    }
    if (personas?.data && personas?.data.length === 0) {
      dispatch(fetchPersonas(customerId));
    }
    if (coreAttributes?.data && coreAttributes?.data.length === 0) {
      dispatch(fetchcoreAttributes(customerId));
    }
  }, [dispatch]);

  const handleDeleteBrand = (brandId) => {
    dispatch(deleteBrand(customerId, brandId)).then(() => {
      dispatch(fetchBrands(customerId));
    });
  };

  function extractDomain(url) {
    if (url?.toString().trim()) {
      const parsedUrl = new URL(url);
      let hostname = parsedUrl.hostname;
      if (hostname.startsWith("www.")) {
        hostname = hostname.slice(4);
      }
      return hostname;
    } else {
      return "brandluminaire.eastus.cloudapp.azure.com";
    }
  }

  function renderToolTipContent(arr) {
    return (
      <React.Fragment>
        {arr.map((p, index) => (
          <React.Fragment key={index}>
            {p.persona.name}
            {index < arr.length - 1 ? ", " : ""}
            <br />
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }

  const renderRow = (item) => (
    <StyledTableRow key={item.id}>
      <StyledTableCell component="th" scope="row" style={{ width: 150 }}>
        <div className={classes.brand_product}>
          <img
            src={`https://img.logo.dev/${extractDomain(
              item.brand?.domain_url
            )}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
          />
          <span>{item.brand.name}</span>
        </div>
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: 180 }}>
        {item.brand.description}
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: 180 }}>
        {item.category.name}
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: 250 }}>
        {item.competitors.map((competitor) => (
          <div className={classes.competitor}>{competitor.brand.name}</div>
        ))}
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: 300 }}>
        {item.persona.slice(0, 2).map((p) => (
          <MUIChip
            label={p.persona.name}
            color={CHIP_COLORS[Math.floor(Math.random() * CHIP_COLORS.length)]}
          />
        ))}
        {item.persona.length - 2 > 0 ? (
          <Tooltip
            title={renderToolTipContent(
              item.persona.slice(2, item.persona.length)
            )}
          >
            <span></span>
            <MUIChip label={`${item.persona.length - 2}+`} color="#858585" />
          </Tooltip>
        ) : null}
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: 100 }}>
        <div className={classes.status}>
          <CheckCircleIcon style={{ color: "#028101" }} />
          <span>{item.is_active == 1 ? "Active" : "Inactive"}</span>
        </div>
      </StyledTableCell>
      <StyledTableCell align="left">
        <div>
          <MUIIconButton
            style={{ color: "#028101" }}
            className={classes.action_btn}
          >
            <PlayArrowIcon />
          </MUIIconButton>
          <MUIIconButton
            style={{ color: "#007aff" }}
            className={classes.action_btn}
            onClick={() => {
              setUpdateModalOpen(true);
              setModalOpen(true);
              console.log(item, "item");
              setBrandName(item.brand.name);
              setDetails(item.brand.description);
              setBrandCategoryName(item.category.name);
              setBrandCategoryDescription(item.category.description);
              setCompetitors(item.competitors);

              createPersonaArray(item.persona);
              setSelectBrandID(item);
            }}
          >
            <CachedIcon />
          </MUIIconButton>
          <MUIIconButton
            style={{ color: "#da1a04" }}
            onClick={() => handleDeleteBrand(item?.id)}
            className={classes.action_btn}
          >
            <DeleteOutlineIcon />
          </MUIIconButton>
          <MUIIconButton
            onClick={() =>
              navigate(
                `/brandonboarding/dashboard?brandId=${item?.id}&brandCustomerId=${item?.customer?.id}&isDetailPage=1`
              )
            }
            style={{ color: "#6118f0" }}
            className={classes.action_btn}
          >
            <SpeedOutlinedIcon />
          </MUIIconButton>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const brandsData = brands?.data?.data?.filter((obj) => obj.is_active);

  const createPersonaArray = (persona) => {
    const result = persona.map((item) =>
      typeof item === "object" ? item.persona_id : item
    );
    setBrandPersona(result);
  };
  return (
    <>
      {brandsData && brandsData.length !== 0 ? (
        <Table
          headers={TABLE_HEADERS}
          data={brandsData}
          render={renderRow}
          orderBy={orderBy}
          order={order}
          onSort={handleRequestSort}
        />
      ) : (
        <Spinner />
      )}
    </>
  );
}
