import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputSelectionComponent from "../Dashboard/Component/inputSelection";
import {
  fetchCustomerLlms,
  getCustomerBrandIndexData,
  getLlms,
  getCustomerProductIndexData,
} from "../../services/apiService";
import DateRangePicker from "../Dashboard/Component/DateRangePicker";
import { AuthContext } from "../../context/auth-context";
import {
  transformCustomerIndexData,
  transformCustomerProductIndexData,
  mergeBrandAndProductData,
  splitByDayAndMonth,
  transformCustomerCoreAttData,
  transformCustomerProductCoreAttData,
} from "../../utils/helpers";
import SummaryCard from "./SummaryCard/SummaryCard";
import { Spinner } from "react-bootstrap";

const HomePage = () => {
  const [selectedLlmItems, setSelectedLlmItems] = useState([]);
  const [selectedSubFrequencyItems, setSelectedSubFrequencyItems] =
    useState("7Days");
  const [dbLlms, setDbLlms] = useState([]);
  const [customerLlm, setCustomerLlm] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [realStartDate, setRealStartDate] = useState();
  const [loading, setLoading] = useState(true);

  const [customerBrandIndexData, setCustomerBrandIndexData] = useState([]);
  const [customerPastBrandIndexData, setCustomerPastBrandIndexData] = useState(
    []
  );

  const handleLlmCheckChange = (selected) => {
    let selectedValues = selected?.map((option) => option?.name);

    setSelectedLlmItems(selectedValues || []);
  };

  const { user } = useContext(AuthContext);

  const customerId = user?.customer_id || 0;

  useEffect(() => {
    const dateMapping = {
      Current: 1,
      "3Days": 5,
      "7Days": 13,
      Month: 59,
      "3Month": 179,
    };

    const realDateMapping = {
      Current: 0,
      "3Days": 2,
      "7Days": 6,
      Month: 29,
      "3Month": 89,
    };

    if (!selectedSubFrequencyItems.includes("Custom")) {
      const currentDate = new Date();
      const timeFactorDate = new Date(currentDate);
      const realTimeFactorDate = new Date(currentDate);

      if (selectedSubFrequencyItems) {
        // Use UTC date setters to avoid time zone issues
        timeFactorDate.setUTCDate(
          currentDate.getUTCDate() - dateMapping[selectedSubFrequencyItems]
        );
        realTimeFactorDate.setUTCDate(
          currentDate.getUTCDate() - realDateMapping[selectedSubFrequencyItems]
        );

        const options = {
          timeZone: "UTC",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        };
        // Convert to locale string in UTC format
        const current = currentDate.toLocaleDateString("en-CA", {
          ...options,
          timeZone: "UTC",
        });
        const past = timeFactorDate.toLocaleDateString("en-CA", {
          ...options,
          timeZone: "UTC",
        });
        const realPast = realTimeFactorDate.toLocaleDateString("en-CA", {
          ...options,
          timeZone: "UTC",
        });

        setEndDate(current);
        setStartDate(past);
        setRealStartDate(realPast);
      }
    }
  }, [selectedSubFrequencyItems]);

  useEffect(() => {
    async function fetchData() {
      const llms = await getLlms();
      const customerSpecificLlms = await fetchCustomerLlms([
        Number(customerId),
      ]);
      setDbLlms(llms?.data);
      setCustomerLlm(customerSpecificLlms?.data);
      setSelectedLlmItems("gpt-4o");
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedLlmItems?.length && startDate) {
      setLoading(true);
      handleBrandIndexData();
    }
  }, [selectedLlmItems, startDate]);

  const getDayWeekMonthYear = (date) => {
    if (!date) return { day: "", month: "", year: "", week: "" };

    // Parse the date string to a UTC date object
    const dateObj = new Date(`${date}T00:00:00Z`);

    // Extract day, month, and year
    const day = dateObj.getUTCDate();
    const month = dateObj.getUTCMonth() + 1; // Months are zero-indexed, so we add 1
    const year = dateObj.getUTCFullYear();

    // Function to calculate the week number
    const getWeekNumber = (date) => {
      const firstDayOfYear = new Date(Date.UTC(date.getUTCFullYear(), 0, 1)); // First day of the year in UTC
      const pastDaysOfYear = (date - firstDayOfYear) / 86400000; // Milliseconds in a day
      return Math.ceil((pastDaysOfYear + firstDayOfYear.getUTCDay() + 1) / 7); // Calculate the week number
    };

    // Get the week number
    const week = getWeekNumber(dateObj);

    // Return the day, month, year, and week
    return { day, month, year, week };
  };

  const getCoreAttriutesData = (data, productData) => {
    const brandSplittedData = splitByDayAndMonth(
      getDayWeekMonthYear(realStartDate)?.day,
      getDayWeekMonthYear(realStartDate)?.month,
      data?.brandCoreAttributesForDateRange
    );

    const productSplittedData = splitByDayAndMonth(
      getDayWeekMonthYear(realStartDate)?.day,
      getDayWeekMonthYear(realStartDate)?.month,
      productData?.productCoreAttributesForDateRange
    );

    const brandCoreAtt = transformCustomerCoreAttData(
      brandSplittedData?.filteredData
    );
    const productCoreAtt = transformCustomerProductCoreAttData(
      productSplittedData?.filteredData
    );

    return { brandCoreAtt, productCoreAtt };
  };

  const handleBrandIndexData = async () => {
    const startDateObj = getDayWeekMonthYear(startDate);
    const endDateObj = getDayWeekMonthYear(endDate);

    const llmIds = dbLlms
      .filter((item) => selectedLlmItems.includes(item?.name))
      .map((obj) => obj?.id);

    const customerLlmIds = customerLlm
      .filter(
        (item) =>
          llmIds.includes(item?.llm_id) &&
          item?.market_id == 1 &&
          item?.geography_id == 1
      )
      .map((obj) => obj?.id);

    const payload = {
      customerId,
      llmIds: customerLlmIds,
      startDay: startDateObj.day,
      startMonth: startDateObj.month,
      startYear: startDateObj.year,
      endDay: endDateObj.day,
      endMonth: endDateObj.month,
      endYear: endDateObj.year,
    };

    let brandIndexData;
    let productIndexData;

    try {
      brandIndexData = await getCustomerBrandIndexData(payload);
      productIndexData = await getCustomerProductIndexData(payload);

      // const coreAttData = getCoreAttriutesData(
      //   brandIndexData,
      //   productIndexData
      // );

      const brandSplittedData = splitByDayAndMonth(
        getDayWeekMonthYear(realStartDate)?.day,
        getDayWeekMonthYear(realStartDate)?.month,
        brandIndexData?.customerBrandIndexScoreData
      );

      const productSplittedData = splitByDayAndMonth(
        getDayWeekMonthYear(realStartDate)?.day,
        getDayWeekMonthYear(realStartDate)?.month,
        productIndexData?.customerBrandProductIndexScoreData
      );
      brandIndexData = transformCustomerIndexData(
        brandSplittedData?.filteredData
      );
      productIndexData = transformCustomerProductIndexData(
        productSplittedData?.filteredData
      );

      let pastBrandIndexData = transformCustomerIndexData(
        brandSplittedData?.remainingData
      );
      let pastProductIndexData = transformCustomerProductIndexData(
        productSplittedData?.remainingData
      );

      // const mergeBrandIndexAndCoreAtt = mergeBrandCoreAttAndIndexData(
      //   brandIndexData,
      //   coreAttData?.brandCoreAtt
      // );

      // const mergeProductIndexAndCoreAtt = mergeBrandProductCoreAttData(
      //   productIndexData,
      //   coreAttData?.productCoreAtt
      // );

      const finalData = mergeBrandAndProductData(
        brandIndexData,
        productIndexData
      );
      const pastFinalData = mergeBrandAndProductData(
        pastBrandIndexData,
        pastProductIndexData
      );
      setCustomerBrandIndexData(finalData);
      setCustomerPastBrandIndexData(pastFinalData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err, {
        autoClose: 700,
      });
    }
  };

  function mergeData(currentData, pastData) {
    return currentData.map((currentBrand) => {
      // Find matching past brand by customerBrandId
      const pastBrand = pastData.find(
        (pastBrand) =>
          pastBrand.customerBrandId === currentBrand.customerBrandId
      );

      if (pastBrand) {
        // Merge brand-level scores and llms
        currentBrand.pastAvgScores = pastBrand.avgScores;
        currentBrand.pastLlms = pastBrand.llms;

        // Iterate over current products and match with past products
        currentBrand.products = currentBrand.products.map((currentProduct) => {
          // Find matching past product by productId
          const pastProduct = pastBrand.products.find(
            (pastProduct) => pastProduct.productId === currentProduct.productId
          );

          if (pastProduct) {
            // Merge product-level scores and llms
            currentProduct.pastAvgScores = pastProduct.avgScores;
            currentProduct.pastLlms = pastProduct.llms;
          }

          return currentProduct;
        });
      }

      return currentBrand;
    });
  }

  const indexScoreDataToDisplay = mergeData(
    customerBrandIndexData,
    customerPastBrandIndexData
  );

  const handlesubFrequencyCheckChange = (key) => {
    setSelectedSubFrequencyItems(key);
  };

  return (
    <>
      <div class="bodycontent">
        <div
          style={{ marginBottom: "-15px" }}
          class="row justify-content-between"
        >
          <div class="col-md-3">
            <InputSelectionComponent
              selectedOptions={selectedLlmItems}
              handleChange={handleLlmCheckChange}
              options={dbLlms}
              type="llm"
            />
          </div>
          <DateRangePicker
            activeTarget={selectedSubFrequencyItems}
            handleChange={handlesubFrequencyCheckChange}
          />
        </div>

        <div class="col-md-12 mt-4">
          <div class="card p-3 custCard">
            {loading ? (
              <div
                style={{
                  fontSize: "19px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  color: "green",
                }}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Please Wait...
              </div>
            ) : (
              <>
                {indexScoreDataToDisplay?.map((data) => {
                  return (
                    <>
                      <SummaryCard
                        frequency={selectedSubFrequencyItems}
                        domainUrl={data?.brandDomain}
                        data={data}
                      />
                      <hr />
                      {data?.products?.map((productData) => {
                        return (
                          <>
                            <SummaryCard
                              frequency={selectedSubFrequencyItems}
                              domainUrl={data?.brandDomain}
                              isProduct={true}
                              data={productData}
                            />
                            <hr />
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default HomePage;
