import { useEffect, useContext, useState } from "react";
import { Skeleton, TableCell, TableHead, TableRow } from "@mui/material";
import axios from "axios";

import Table, {
  StyledTableCell,
  StyledTableRow,
} from "./../../../Component/UI/Table/Table";
import MUICard from "./../../../Component/UI/MUICard/MUICard";
import { AILabel } from "../../../Component/AIGenerated/AIGenerated";
import classes from "./TopProduct.module.css";
import { DiscoveryContext } from "./../discovery-context";
import ErrorView from "../../../Component/ErrorView/ErrorView";

export default function TopProducts({ entity }) {
  const [error, setError] = useState();
  const discoveryCtx = useContext(DiscoveryContext);

  useEffect(() => {
    if (discoveryCtx.top_products.length > 0) return;
    getTopProducts();
  }, [discoveryCtx.top_products.length]);

  async function getTopProducts() {
    setError(null);
    try {
      const payload = {
        brand: entity,
        llm: discoveryCtx.llm,
      };
      const response = await axios.post(
        "https://brandluminaire.eastus.cloudapp.azure.com/api/metrics/top-products",
        payload
      );
      discoveryCtx.updatedTopProducts(response.data.products);
    } catch (error) {
      console.log("Error: ", error.message);
      setError(error.message);
    }
  }

  function LoadingSkeletion() {
    return (
      <div className="p-4">
        {Array(3)
          .fill(0)
          .map((_, index) => {
            return (
              <div
                key={index}
                style={{ display: "flex", flexDirection: "row", marginTop: 20 }}
              >
                <Skeleton
                  width={"100%"}
                  style={{ flex: 1 }}
                  className="mx-2"
                  height={30}
                />
                <div
                  style={{ display: "flex", flexDirection: "column", flex: 3 }}
                >
                  <Skeleton width={"100%"} className="mx-2" height={20} />
                  <Skeleton width={"100%"} className="mx-2" height={20} />
                  <Skeleton width={"70%"} className="mx-2" height={20} />
                </div>
              </div>
            );
          })}
      </div>
    );
  }

  function renderRows(item, index) {
    return (
      <StyledTableRow
        key={index}
        style={{ background: index % 2 == 0 ? "#fff" : "#eef2f6" }}
      >
        <StyledTableCell className={classes.product_name}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell className={classes.product_des}>
          {item.summary}
        </StyledTableCell>
      </StyledTableRow>
    );
  }

  let products = [];
  let loading = true;

  if (discoveryCtx.top_products.length > 0) {
    products = discoveryCtx.top_products;
    loading = false;
  }

  function renderHeaders() {
    return (
      <TableHead className={classes.table_head}>
        <TableRow>
          <TableCell className={classes.table_head_text_comp}>
            Most popular products / services
          </TableCell>
          <TableCell className={classes.table_head_cell}>
            <AILabel />
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <MUICard elevation={0} variant="elevated" className={classes.card}>
      <div>
        {products.length > 0 && !error && (
          <Table
            elevation={0}
            data={products}
            render={renderRows}
            renderHeaders={renderHeaders}
          />
        )}
        {loading && !error && <LoadingSkeletion />}
        {error && (
          <div className={classes.error}>
            <ErrorView
              title={"Error getting top products"}
              message={error}
              retry
              onRetry={() => getTopProducts()}
            />
          </div>
        )}
      </div>
    </MUICard>
  );
}
