import React, { useEffect, useState } from "react";
import axios from "axios";
import { Skeleton } from "@mui/material";

import MessageList from "./MessageList";
import classes from "./LLM.module.css";

const LLM = ({ llm, onMessage }) => {
  const [loading, setLoading] = useState(false);

  async function getLLMResponse() {
    let payload = "";
    if (llm.model === "gpt-4o") {
      payload = {
        llm: llm.model,
        payload: {
          messages: llm.messages,
          temperature: 0.1,
          max_tokens: 8000,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
          model: "gpt-4o",
        },
      };
    }
    if (llm.model === "Claude3") {
      payload = {
        llm: llm.model,
        payload: {
          model: "claude-3-5-sonnet-20240620",
          max_tokens: 5000,
          temperature: 0.1,
          messages: llm.messages.map((message) => ({
            role: message.role,
            content: message.content,
          })),
        },
      };
    }
    if (llm.model === "Perplexity") {
      payload = {
        llm: llm.model,
        payload: {
          model: "llama-3.1-sonar-large-128k-online",
          messages: llm.messages,
          max_tokens: 5000,
          temperature: 0.1,
        },
      };
    }
    if (llm.model === "Gemini") {
      payload = {
        llm: llm.model,
        payload: {
          messages: llm.messages.slice(0, -1).map((msg) => {
            return {
              role: msg.role === "assistant" ? "model" : "user",
              parts: [
                {
                  text: msg.content,
                },
              ],
            };
          }),
          prompt: llm.messages.slice(-1)[0].content,
          temperature: 0.1,
        },
      };
    }

    try {
      setLoading(true);
      const response = await axios.post(
        "https://brandluminaire.eastus.cloudapp.azure.com/api/v2/llm-execute",
        payload
      );

      onMessage(llm.name, {
        content: response.data.data,
        id: crypto.randomUUID(),
        role: "assistant",
      });
    } catch (error) {
      console.log(error);
      onMessage(llm.name, {
        content: `Error: ${error?.message || "Something went wrong"}`,
        id: crypto.randomUUID(),
        role: "assistant",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (llm.messages.length === 0) return;
    const lastMessage = llm.messages[llm.messages.length - 1];
    if (lastMessage.role === "user" && lastMessage.content != "") {
      getLLMResponse();
    }
  }, [llm.messages.length]);

  return (
    <div className={classes.chat}>
      <div className={classes.header}>
        <img className={classes.img} src={llm.img} />
        <span className={classes.name}>{llm.name}</span>
      </div>
      <div className={classes.chat_content}>
        <MessageList messages={llm.messages} loading={loading} />
      </div>
    </div>
  );
};

export default LLM;
