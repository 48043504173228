import React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import { CardTable } from "./CardTable";
import RadialGraph from "./Gauge";
import { extractDomain } from "../../../utils/helpers";
import { useNavigate } from "react-router";
import { FaArrowRight } from "react-icons/fa";
import { Button } from "react-bootstrap";
import EmptyView from "../../../Component/EmptyView/EmptyView";

const SummaryCard = ({ data, isProduct, domainUrl, frequency }) => {
  const navigate = useNavigate();

  const dateMapping = {
    Current: "Current",
    "3Days": "Last 3 Days",
    "7Days": "Last 7 Days",
    Month: "Last 30 Days",
    "3Month": "Last 90 Days",
  };

  function compareLlmScores(data) {
    const { brandName, llms, pastLlms } = data;
    const attributes = [
      "avg_brand_index_score",
      "avg_brand_favorability_score",
      "avg_brand_reach_score",
    ];
    const results = [];

    // Loop through each llm in current data
    for (const llmName in llms) {
      if (llms.hasOwnProperty(llmName) && pastLlms?.hasOwnProperty(llmName)) {
        // Compare each attribute score
        attributes.forEach((attr) => {
          const currentScore = llms[llmName][attr];
          const pastScore = pastLlms[llmName][attr];

          // Calculate the difference
          const difference = currentScore - pastScore;

          // Check if the difference is greater or equal to 5
          if (Math.abs(difference) >= 5) {
            const status = difference > 0 ? "increased" : "decreased";

            // Modify the message for favorability score to "brand sentiment"
            let attributeName = attr
              .replace("avg_", "")
              .replace("_score", "")
              .replace(/_/g, " ");
            if (attr === "avg_brand_favorability_score") {
              attributeName = "brand sentiment";
            }

            const message = `${llmName} - ${brandName} (${attributeName}) ${status} by ${Math.abs(
              difference
            )}.`;

            // Store the message and status in an object
            results.push({
              message,
              status,
            });
          }
        });
      }
    }

    return results;
  }

  const brandIndexAlertsData = compareLlmScores(data) || [];

  const handleNavigation = () => {
    if (isProduct) {
      navigate(
        `/luminaire/product-monitoring?brandProductId=${data?.customerProductId}`
      );
    } else {
      navigate(`/luminaire/brand-monitoring?brandId=${data?.customerBrandId}`);
    }
  };

  return (
    <div>
      <div class="mb-3 d-flex justify-content-between align-items-center">
        <div class="mb-3 d-flex align-items-center">
          <img
            style={{
              width: "32px",
              height: "28px",
              borderRadius: "6px",
              marginBottom: "8px", // Adds spacing between logo and text
            }}
            src={`https://img.logo.dev/${extractDomain(
              domainUrl
            )}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
            alt={""}
          />
          &nbsp; &nbsp;
          <h5 class="me-3">
            {isProduct ? data?.productName : data?.brandName}
          </h5>
        </div>

        <Button
          style={{
            backgroundColor: "#007AFF",
            color: "#EFF4F8",
            fontSize: "14px",
            width: "120px",
            height: "35px",
            textAlign: "center",
          }}
          onClick={handleNavigation}
        >
          Monitoring
        </Button>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="graphGreyBg">
            <div class="row align-items-center justify-content-center">
              <div class="col reachCount">
                <p class="m-0" style={{ color: "#292B2C" }}>
                  Reach
                </p>
                <p class="m-0 prdCount">
                  {Number(data?.avgScores?.avg_brand_reach_score).toFixed(0)}
                </p>
              </div>
              <div class="col reachCount">
                <p class="m-0" style={{ color: "#292B2C" }}>
                  Sentiment
                </p>
                <p class="m-0 prdCount">
                  {Number(
                    data?.avgScores?.avg_brand_favorability_score
                  ).toFixed(0)}
                </p>
              </div>
            </div>
            <div class="d-flex align-items-center mt-3 flex-column mb-3">
              <p style={{ color: "#626262" }} class="mb-2">
                Brand Index Score
              </p>
              {/* <img src="images/graph1.png" class="w-100" /> */}
              <RadialGraph
                label={`Brand Index Score`}
                value={{
                  RBI: Number(data?.avgScores?.avg_brand_index_score).toFixed(
                    0
                  ),
                }}
                pastValue={{
                  RBI: Number(
                    data?.pastAvgScores?.avg_brand_index_score
                  ).toFixed(0),
                }}
                Range={100}
                frequencyLabel={dateMapping[frequency]}
              />
            </div>
            <br />
            <br />
            <div style={{ marginTop: "-80px" }} class="graphsummary prdSummary">
              <CardTable llms={data?.llms} />
            </div>
          </div>
        </div>

        <div className="col-md-8">
          <table className="table table-striped">
            <thead>
              <tr className="prdtblHeader">
                <th style={{ textAlign: "center" }}>Alerts</th>{" "}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <EmptyView message="Alerts Not Available" />{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
