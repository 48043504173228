import React, { useState } from "react";
import { fetchDataFromFirebase } from "../DatabaseFirebase/firebaseService";

export const AuthContext = React.createContext({
  token: null,
  setUserToken: (token) => {},
  clearSession: () => {},
  user: null,
});

const AuthContextProvider = (props) => {
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [isCurrentUserAdmin, setIsCurrentUserAdmin] = useState(
    JSON.parse(localStorage.getItem("isAdmin")) || false
  );
  const [authUserEmail, setAuthUserEmail] = useState(
    localStorage.getItem("authEmail") || ""
  );
  const [loggedInUser, setLoggedInUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  const [firebaseAuthUserData, setFirebaseAuthUserData] = useState(
    JSON.parse(localStorage.getItem("firebaseUser"))
  );

  const setUser = (token, loggedInUserEmail, user) => {
    fetchDataFromFirebase((data) => {
      if(data !== null) {
      const users = Object.values(data);
      const authUser = users.filter((user) => user.email === loggedInUserEmail);
      setFirebaseAuthUserData(authUser)
      localStorage.setItem("firebaseUser", JSON.stringify(authUser));
      if (authUser && authUser.length !== 0 && authUser[0]?.role === "admin") {
        setIsCurrentUserAdmin(true);
        localStorage.setItem("isAdmin", true);
      } else {
        setIsCurrentUserAdmin(false);
        localStorage.setItem("isAdmin", false);
      }
    }}, "Users");
    localStorage.setItem("token", token);

    localStorage.setItem("authEmail", loggedInUserEmail);
    setToken(token);
    setAuthUserEmail(loggedInUserEmail);
    localStorage.setItem("user", JSON.stringify(user));
    setLoggedInUser(user);
  };

  const clearSession = () => {
    setUser(null);
    // localStorage.removeItem("isAdmin");
    // localStorage.removeItem("authEmail");
    localStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        token: token,
        setUser: setUser,
        clearSession: clearSession,
        isCurrentUserAdmin: isCurrentUserAdmin,
        authUserEmail: authUserEmail,
        user: loggedInUser,
        loggedInUser: firebaseAuthUserData,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
