import React, { useState, useEffect } from "react";
import {
  prepareTableData,
  transformDataForMultibarChart,
  extractDomain,
  getFilteredPurchaseDimensions,
} from "../../../utils/helpers";
import ReMultibarChart from "../common/ReMultibarChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import classes from "./DashboardComponent.module.css";
import CustomSelectWithApply from "../common/CustomSelectionComponent";
import { titleMapping } from "../../../utils/constant";
import CustomTooltip from "../common/CustomTooltip";
import EmptyView from "../../../Component/EmptyView/EmptyView";

const CompComparisonComponent = ({
  compCoreAttributes,
  pastCompCoreAttributes,
  compCategoryDimensions,
  pastCompCategoryDimensions,
  compBrandIndexScore,
  pastCompBrandIndexScore,
  compPositiveAttributes,
  compNegativeAttributes,
  brandDomains,
}) => {
  const filteredPurchaseDmesnions = getFilteredPurchaseDimensions(
    compCategoryDimensions
  );
  const tableData = prepareTableData(
    compCoreAttributes,
    compBrandIndexScore,
    // compCategoryDimensions,
    filteredPurchaseDmesnions,
    pastCompCoreAttributes,
    pastCompBrandIndexScore,
    pastCompCategoryDimensions
  );

  const targetHeaders = tableData?.headers;

  // comp filter

  const options = tableData?.headers; // Array of strings
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [selectedCompIndex, setSelectedCompIndex] = useState([]);

  useEffect(() => {
    if (selectedOptions.length) {
      const indexes = selectedOptions.map(
        (input) => targetHeaders?.indexOf(input)
        // tableData?.headers.indexOf(input)
      );
      setSelectedCompIndex(indexes);
      setIsFilterActive(true);
    } else {
      setSelectedCompIndex([]);
      setIsFilterActive(false);
    }
  }, [selectedOptions]);

  const handleChange = (selected) => {
    setSelectedOptions(selected ? selected.map((item) => item.label) : []);
  };

  // comp brand pagination
  const [startIndex, setStartIndex] = useState(0);
  const pageSize = 5; //
  const visibleHeaders = isFilterActive
    ? targetHeaders.filter((item) => selectedOptions.includes(item))
    : targetHeaders.slice(startIndex, startIndex + pageSize);
  // ? tableData?.headers.filter((item) => selectedOptions.includes(item))
  // : tableData?.headers.slice(startIndex, startIndex + pageSize);
  const canGoBack = startIndex > 0;
  const canGoForward = startIndex + pageSize < tableData?.headers.length;

  const handleNext = () => {
    if (canGoForward) {
      setStartIndex(startIndex + pageSize);
    }
  };

  const handlePrevious = () => {
    if (canGoBack) {
      setStartIndex(startIndex - pageSize);
    }
  };

  const getFilteredValues = (values) => {
    if (!isFilterActive) {
      return values.slice(startIndex, startIndex + pageSize);
    } else {
      return selectedCompIndex
        .filter((index) => index !== -1) // Filter out invalid indexes (-1)
        .map((index) => values[index]);
    }
  };

  const filteredData = (data, keysToKeep) => {
    return data
      .map((obj) => {
        const newObj = { name: obj.name };

        keysToKeep.forEach((key) => {
          if (obj.hasOwnProperty(key)) {
            newObj[key] = obj[key];
          }
        });

        return Object.keys(newObj).length > 1 ? newObj : null;
      })
      .filter((obj) => obj !== null);
  };

  let positiveAttBarChartData = transformDataForMultibarChart(
    compPositiveAttributes
  );
  let negativeAttBarChartData = transformDataForMultibarChart(
    compNegativeAttributes
  );

  positiveAttBarChartData = filteredData(
    positiveAttBarChartData,
    visibleHeaders
  );
  negativeAttBarChartData = filteredData(
    negativeAttBarChartData,
    visibleHeaders
  );
  const chartDataArray = [positiveAttBarChartData, negativeAttBarChartData];

  return (
    <>
      <div className="col-md-12 mt-4">
        <div
          className="d-flex justify-content-evenly"
          style={{
            margin: "-10px 0px 10px 63%",
            width: "40%",
            // marginLeft: "96%",
            cursor: "pointer",
          }}
        >
          <CustomSelectWithApply
            selectedOptions={selectedOptions}
            options={options}
            handleChange={handleChange}
          />

          {!isFilterActive && canGoBack && (
            <i
              style={{ fontSize: "22px", marginTop: "5px" }}
              onClick={handlePrevious}
              className="bi bi-arrow-left-circle-fill"
            ></i>
          )}
          {!isFilterActive && canGoForward && (
            <i
              style={{ fontSize: "22px", marginTop: "5px" }}
              onClick={handleNext}
              className="bi bi-arrow-right-circle-fill"
            ></i>
          )}
        </div>
        <div className="card custCard">
          <table className="table table-striped">
            <thead>
              <tr className="tblHeader text-center">
                <th
                  scope="col"
                  style={{
                    width: "150px",
                    textAlign: "left",
                    paddingLeft: "15px",
                    verticalAlign: "middle",
                  }}
                >
                  RBI
                </th>

                {visibleHeaders.map((header, idx) => (
                  <th
                    scope="col"
                    key={idx}
                    style={{
                      width: "100px",
                      verticalAlign: "top", // Ensures the header remains at the top
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", // Centers the content horizontally
                        justifyContent: "flex-start", // Aligns the logo at the top
                        height: "100%", // Ensures full height usage
                      }}
                    >
                      <img
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "6px",
                          marginBottom: "5px", // Adds spacing between logo and text
                        }}
                        src={`https://img.logo.dev/${extractDomain(
                          brandDomains?.[header]
                        )}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
                        alt={""}
                      />
                      <span
                        style={{
                          wordWrap: "break-word", // Ensures long text wraps
                          textAlign: "center",
                          marginTop: "5px", // Adds space between logo and text
                        }}
                      >
                        {header}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {tableData.tables.map((table, tableIndex) => (
                <React.Fragment key={tableIndex}>
                  {tableIndex !== 0 && <br />}

                  <tr className="tblHeader">
                    {tableIndex !== 0 && (
                      <th
                        style={{ textAlign: "left", paddingLeft: "15px" }}
                        colSpan={tableData.headers.length + 1}
                      >
                        {table.title}
                      </th>
                    )}
                  </tr>
                  {table?.rows && table?.rows.length ? (
                    table.rows.map((row, idx) => (
                      <tr key={idx}>
                        <td
                          valign="middle"
                          style={{
                            width: "150px",
                            textAlign: "left",
                            paddingLeft: "15px",
                          }}
                        >
                          {titleMapping?.[row.attribute] || row.attribute}
                        </td>
                        {getFilteredValues(row.values)
                          // .slice(startIndex, startIndex + pageSize)
                          .map((val, valIdx) => {
                            const isColorApply =
                              table?.title == "RBI" ||
                              table?.title == "Rank" ||
                              table?.title == "Favorability";
                            let color = "#dc3545";
                            if (Number(val?.value).toFixed(0) >= 7)
                              color = "#2196F3";
                            if (Number(val?.value).toFixed(0) >= 9)
                              color = "#198754";
                            return (
                              <td
                                key={valIdx}
                                style={{
                                  width: "100px",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <CustomTooltip explaination={val?.explaination}>
                                  <div
                                    // title={val?.explaination}
                                    className="d-flex align-items-center justify-content-center flex-row"
                                  >
                                    <span
                                      style={{
                                        color:
                                          !isColorApply && !val?.isNa
                                            ? color
                                            : "grey",
                                      }}
                                      className={`h5 mb-0 me-1
                              } ${classes["main-value"]}`}
                                    >
                                      {!val?.isNa
                                        ? Number(val?.value).toFixed(0)
                                        : "NA"}
                                    </span>
                                    {val?.change != undefined && !val?.isNa && (
                                      <>
                                        <FontAwesomeIcon
                                          className={
                                            val?.change < 0
                                              ? `${classes["down-icon"]} text-danger me-1`
                                              : `${classes["up-icon"]} text-success me-1`
                                          }
                                          icon={
                                            val?.change < 0
                                              ? faSortDown
                                              : faSortUp
                                          }
                                        />
                                        <span className={classes.delta}>
                                          {val?.change > 0
                                            ? `${Number(val?.change).toFixed(
                                                0
                                              )}`
                                            : Number(
                                                Math.abs(val?.change)
                                              ).toFixed(0)}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </CustomTooltip>
                              </td>
                            );
                          })}
                      </tr>
                    ))
                  ) : (
                    <EmptyView message="Data Not Available" />
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {chartDataArray?.map((data, index) => {
        return (
          <div className="col-md-12 mt-4">
            <div className="card p-3 custCard">
              <div className="mb-3 d-flex justify-content-between align-items-center"></div>
              <div>
                <ReMultibarChart
                  index={index}
                  data={data}
                  isCompetitor={true}
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CompComparisonComponent;
