export const CardTable = ({ llms }) => {
  return (
    <div className="idReSe">
      <table className="table-sm w-100 text-center table-borderless">
        <thead>
          <tr style={{ color: "#7A7A7A", fontWeight: 500, lineHeight: "18px" }}>
            <th scope="col"></th>
            <th scope="col">Index</th>
            <th scope="col">Reach</th>
            <th scope="col">Sentiment</th>
          </tr>
        </thead>
        <tbody>
          {/* Iterate through the LLMs dynamically */}
          {Object.keys(llms).map((llmName) => (
            <tr
              style={{
                color: "#7A7A7A",
                fontWeight: 500,
                lineHeight: "18px",
              }}
              key={llmName}
            >
              <th scope="row">{llmName}</th>
              <td>
                {Number(llms?.[llmName]?.avg_brand_index_score).toFixed(0)}
              </td>
              <td>
                {Number(llms?.[llmName]?.avg_brand_reach_score).toFixed(0)}
              </td>
              <td>
                {Number(llms?.[llmName]?.avg_brand_favorability_score).toFixed(
                  0
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
