import { IconButton, CircularProgress } from "@mui/material";

export default function MUIIconButton({ icon, loading, ...props }) {
  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center px-4">
          <CircularProgress size={16} />
        </div>
      ) : (
        <IconButton {...props}></IconButton>
      )}
    </>
  );
}
