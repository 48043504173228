import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const sortedPayload = payload?.sort((a, b) => {
      return b["value"] - a["value"];
    });
    return (
      <div className="custom-tooltip-container">
        <ul style={{ paddingLeft: 10, margin: 0 }}>
          {sortedPayload?.map((obj, index) => {
            return (
              <li key={index}>
                {obj?.name} : {obj?.value}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return null;
};

const ReLineChart = ({ data }) => {
  const colors = [
    "#8884d8",
    "#82ca9d",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#A28ADF",
    "#F44E54",
    "#2A9D8F",
    "#E76F51",
    "#264653",
  ];

  const brands =
    data && data.length !== 0
      ? [
          ...new Set(
            data.flatMap((obj) =>
              Object.keys(obj).filter((key) => key !== "date")
            )
          ),
        ]
      : [];

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      style={{ backgroundColor: "white" }}
    >
      <LineChart data={data} margin={{ right: 5 }}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="date"
          axisLine={{ stroke: "white" }}
          tickLine={false}
          tickMargin={7}
        />
        <YAxis
          domain={[0, 100]} // Set the domain from 0 to 100
          tickCount={11} // Number of ticks (0, 10, 20, ... 100)
          axisLine={{ stroke: "white" }}
          tickLine={false}
          tickMargin={12}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top" />
        {brands &&
          brands.length > 0 &&
          brands?.map((brand, index) => (
            <Line
              key={brand}
              type="monotone"
              dataKey={brand}
              stroke={colors[index % colors.length]}
              fill={colors[index % colors.length]}
            />
          ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ReLineChart;
