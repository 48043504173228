import React from "react";
import { Chip } from "@mui/material";

import classes from "./MUIChip.module.css";

export default function MUIChip({ label, color }) {
  return (
    <Chip
      label={label}
      size="small"
      style={{ backgroundColor: color }}
      className={classes.chip}
    />
  );
}
