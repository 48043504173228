import { Button, CircularProgress } from "@mui/material";

export default function MUIButton({
  loading,
  children,
  startIcon,
  endIcon,
  variant = "contained",
  ...props
}) {
  return (
    <Button
      variant={variant}
      disableElevation
      disabled={loading}
      className="no-print"
      {...props}
      startIcon={startIcon && !loading ? startIcon : null}
      endIcon={endIcon && !loading ? endIcon : null}
      style={{
        ...props.style,
        textTransform: "capitalize",
        background:
          props.variant === "contained" && !loading
            ? "#007AFF"
            : loading
            ? "lightgray"
            : "",
        fontWeight: 300,
        color: props.variant === "outlined" ? "#000" : "#eff4f8",
      }}
    >
      {loading ? (
        <div className="d-flex align-items-center py-1">
          <CircularProgress size={16} />
        </div>
      ) : (
        children
      )}
    </Button>
  );
}
