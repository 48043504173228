import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Skeleton,
} from "@mui/material";
import axios from "axios";
import Markdown from "markdown-to-jsx";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ErrorView from "./../../Component/ErrorView/ErrorView";
import MUIButton from "./../../Component/UI/MUIButton/MUIButton";
import classes from "./Summary.module.css";

const Summary = ({ messages, loading: isResponseLoading }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [summary, setSummary] = useState("");
  const [expanded, setExpanded] = useState(false);

  const isHistoryResetted = messages.every((item) => item.msgs.length == 0);

  useEffect(() => {
    if (isHistoryResetted) {
      setSummary("");
    }
  }, [isHistoryResetted]);

  function getLastMessageFromConversations() {
    return messages.map((msg) => {
      return {
        name: msg.llm,
        message: msg.msgs.slice(-1)[0]?.content,
      };
    });
  }

  async function generateSummary() {
    const messages = getLastMessageFromConversations();
    if (messages.every((msg) => !msg.message || msg.message.length == 0)) {
      return;
    }
    if(isResponseLoading) return;

    let prompt = "";
    messages.forEach((msg) => {
      prompt += `#${msg.name}: ${msg.message}\n\n`;
    });
    prompt += `take the following outputs across LLMs and identify the common themes and major differences. When analyzing and describing common themes and major differences focus on fact and content common themes and differences. DO NOT focus on level of analysis, level of detail, overall comprehensiveness, level of conciseness, or structure of the results. If sources are listed in the outputs, please be sure to provide themes and major differences for that category. Keep your response concise. It is fine to use bullets. Create a brief summary paragraph at the end listing the 3 major themes, and 3 major differences.`;

    const payload = {
      llm: "gpt-4o",
      payload: {
        messages: [
          {
            role: "user",
            content: prompt,
          },
        ],
        temperature: 1,
        max_completion_tokens: 60536,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        model: "o1-mini",
      },
    };

    try {
      setLoading(true);
      setExpanded(true);
      const response = await axios.post(
        "https://brandluminaire.eastus.cloudapp.azure.com/api/v2/llm-execute",
        payload
      );
      const data = response.data.data;
      setSummary(data);
    } catch (error) {
      setError(error?.message || "Something has went wrong");
    } finally {
      setLoading(false);
    }
  }

  function getSkeleton() {
    return (
      <>
        <Skeleton width={"100%"} />
        <Skeleton width={"80%"} />
        <Skeleton width={"90%"} />
        <Skeleton width={"95%"} />
        <Skeleton width={"70%"} />
      </>
    );
  }

  let content = "";
  if (!loading && summary && !error) {
    content = <Markdown className={classes.text}>{summary}</Markdown>;
  }
  if (loading) {
    content = getSkeleton();
  }
  if (error) {
    content = (
      <div className="mt-4">
        <ErrorView title="An error occured" message={error} />
      </div>
    );
  }

  return (
    <div className={classes.box}>
      <Accordion
        elevation={0}
        expanded={expanded}
        onChange={() => setExpanded((value) => !value)}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.title}
        >
          LLM Synthesis and Comparison
        </AccordionSummary>
        <AccordionDetails>{content}</AccordionDetails>
        <AccordionActions>
          {!loading && !isResponseLoading && (
            <MUIButton
              onClick={generateSummary}
              variant="outlined"
              className={classes.regenerate}
              startIcon={<AutoAwesomeOutlinedIcon />}
            >
              Regenerate
            </MUIButton>
          )}
        </AccordionActions>
      </Accordion>
    </div>
  );
};

export default Summary;
