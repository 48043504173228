import React from "react";

import { transformDataForMultibarChart } from "../../../utils/helpers";
import ReVerticalMultibarChart from "../common/ReVerticalMultibarChart";

const SourceComponent = ({
  positiveAttSource,
  negativeAttSource,
  categoryDimensionSource,
  categoryDimensionRatingSource,
  coreAttSourceData,
  reachSourceData,
  sentimentSourceData,
}) => {
  const positiveAttSourceBarChartData =
    transformDataForMultibarChart(positiveAttSource);
  const negativeAttSourceBarChartData =
    transformDataForMultibarChart(negativeAttSource);

  const categoryDimSourceBarChartData = transformDataForMultibarChart(
    categoryDimensionSource
  );
  const categoryDimRatingSourceBarChartData = transformDataForMultibarChart(
    categoryDimensionRatingSource
  );

  const coreAttSourceBarChartData =
    transformDataForMultibarChart(coreAttSourceData);
  const reachSourceBarChartData =
    transformDataForMultibarChart(reachSourceData);
  const sentimentSourceBarChartData =
    transformDataForMultibarChart(sentimentSourceData);

  const aggregatedBrandSources = mergeMultipleArrays(
    sentimentSourceBarChartData,
    positiveAttSourceBarChartData,
    negativeAttSourceBarChartData
  );

  const aggregatedCategorySources = mergeMultipleArrays(
    reachSourceBarChartData,
    categoryDimSourceBarChartData,
    categoryDimRatingSourceBarChartData,
    coreAttSourceBarChartData
  );

  const brandSourcesChartDataArray = [
    aggregatedBrandSources,
    sentimentSourceBarChartData,
    positiveAttSourceBarChartData,
    negativeAttSourceBarChartData,
  ];

  const categorySourcesChartDataArray = [
    aggregatedCategorySources,
    reachSourceBarChartData,
    categoryDimSourceBarChartData,
    categoryDimRatingSourceBarChartData,
    coreAttSourceBarChartData,
  ];

  function mergeMultipleArrays(...arrays) {
    const merged = {};

    arrays.flat().forEach((item) => {
      // Check if the item is defined and has a 'name' property
      if (item && typeof item.name === "string") {
        const { name, ...llms } = item;

        // If the name is not yet in the merged object, add it
        if (!merged[name]) {
          merged[name] = llms;
        } else {
          // Count the number of LLMs for the existing entry and the current item
          const existingLLMsCount = Object.keys(merged[name]).length;
          const newLLMsCount = Object.keys(llms).length;

          // If the current item has more LLMs, replace the existing one
          if (newLLMsCount > existingLLMsCount) {
            merged[name] = llms;
          }
        }
      }
    });

    // Convert the merged object back to an array
    return Object.entries(merged).map(([name, llms]) => ({
      name,
      ...llms,
    }));
  }

  return (
    <>
      <h4 style={{ textAlign: "center", margin: "10px 0px -10px 0px" }}>
        Brand Query Sources
      </h4>
      {brandSourcesChartDataArray
        // ?.filter((item) => item.length)
        ?.map((data, index) => {
          return (
            <div className="col-md-12 mt-4">
              <div className="card p-3 custCard">
                <div className="mb-3 d-flex justify-content-between align-items-center"></div>
                <div>
                  <ReVerticalMultibarChart
                    isBrandSources={true}
                    index={index}
                    data={data}
                  />
                </div>
              </div>
            </div>
          );
        })}
      <hr />
      <h4 style={{ textAlign: "center", margin: "10px 0px -10px 0px" }}>
        Category Query Sources
      </h4>
      {categorySourcesChartDataArray
        // ?.filter((item) => item.length)
        ?.map((data, index) => {
          return (
            <div className="col-md-12 mt-4">
              <div className="card p-3 custCard">
                <div className="mb-3 d-flex justify-content-between align-items-center"></div>
                <div>
                  <ReVerticalMultibarChart
                    isBrandSources={false}
                    index={index}
                    data={data}
                  />
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default SourceComponent;
