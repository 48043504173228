import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import {
  deleteGeoMarket,
  fetchGeoMarkets,
} from "../../../redux/actions/geoMarketsActions";
import classes from "../Brand/Brand.module.css";
import { AuthContext } from "../../../context/auth-context";
import Table, {
  StyledTableCell,
  StyledTableRow,
} from "../../../Component/UI/Table/Table";
import MUIIconButton from "./../../../Component/UI/MUIIconButton/MUIIconButton";
import Spinner from "./../../../Component/Spinner/Spinner";

const TABLE_HEADERS = [
  { id: "Geography", name: "Geography", sorting: true },
  { id: "Markets", name: "Markets", sorting: false },
  { id: "actions", name: "Actions", sorting: false },
];

const Geography = ({ handleSelectedData }) => {
  const dispatch = useDispatch();
  const geoMarkets = useSelector((state) => state.geoMarkets);
  const { user } = useContext(AuthContext);
  const customerId = user?.customer_id || 0;

  useEffect(() => {
    if (geoMarkets?.data && geoMarkets?.data.length === 0) {
      dispatch(fetchGeoMarkets(customerId));
    }
  }, []);

  if (geoMarkets.loading) {
    return <Spinner />;
  }

  const geoMarketsData = geoMarkets.data.data;

  if (geoMarketsData && geoMarketsData.length > 0) {
    // Step 1: Create a map to group markets by geography
    const geographyMap = geoMarketsData.reduce((acc, item) => {
      const geographyName = item.geography.name;
      const marketName = item.market.name;
      const geographyId = item.geography_id;

      // If geography is not in the map, add it with an empty array for markets
      if (!acc[geographyName]) {
        acc[geographyName] = {
          geographyId, // Store the geographyId
          markets: new Set(),
        };
      }

      // Add the market to the corresponding geography's market set
      acc[geographyName].markets.add(marketName);

      return acc;
    }, {});

    // Step 2: Convert the map into an array of objects for rendering
    const tableData = Object.entries(geographyMap).map(
      ([geography, { geographyId, markets }]) => ({
        geography,
        geographyId,
        markets: Array.from(markets), // Keep markets as an array of strings
      })
    );

    const renderRow = (item) => (
      <StyledTableRow key={item.id}>
        <StyledTableCell component="th" scope="row" style={{ width: 450 }}>
          <div className={classes.brand_product}>
            <span>{item.geography}</span>
          </div>
        </StyledTableCell>
        <StyledTableCell align="left" style={{ width: 450 }}>
          {item.markets.map((market, index) => (
            <div className={classes.brand_product}>
              <span>{market}</span>
              {console.log(item, "all")}
            </div>
          ))}
        </StyledTableCell>

        <StyledTableCell align="left">
          <div>
            <MUIIconButton
              style={{ color: "#007aff" }}
              className={classes.action_btn}
            >
              <CachedIcon />
            </MUIIconButton>
            <MUIIconButton
              style={{ color: "#da1a04" }}
              className={classes.action_btn}
              onClick={() => handleSelectedDeleteData(item.geographyId)}
            >
              <DeleteOutlineIcon />
            </MUIIconButton>
          </div>
        </StyledTableCell>
      </StyledTableRow>
    );
    const handleSelectedDeleteData = (item) => {
      console.log(item, "item");
      dispatch(deleteGeoMarket(customerId, item)).then(() => {
        dispatch(fetchGeoMarkets(customerId));
      });
    };
    return (
      <Table headers={TABLE_HEADERS} data={tableData} render={renderRow} />
    );
  }

  return <Spinner />;
};

export default Geography;
