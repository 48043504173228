import { useEffect, useContext, useState } from "react";
import axios from "axios";
import {
  Skeleton,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import Table, {
  StyledTableCell,
  StyledTableRow,
} from "./../../../Component/UI/Table/Table";
import MUICard from "./../../../Component/UI/MUICard/MUICard";
import classes from "./CoreAttributes.module.css";
import { DiscoveryContext } from "../discovery-context";
import { extractDomain } from "../../../utils/helpers";
import ErrorView from "../../../Component/ErrorView/ErrorView";

const TABLE_HEADERS = [
  {
    id: "attr",
    name: "Brand Pillars",
  },
];

export default function CoreAttributes({ category }) {
  const [error, setError] = useState();
  const discoveryCtx = useContext(DiscoveryContext);

  async function getCoreAttributes() {
    setError(null);
    const competitors = discoveryCtx.competitors.map((c) => c.name);

    const promiseArray = [];
    competitors.forEach((competitor) => {
      if(discoveryCtx.llm === "Perplexity") {
        promiseArray.push(new Promise(res=> setTimeout(()=> res(''), 60000)));
      }
      promiseArray.push(
        axios.post(
          "https://brandluminaire.eastus.cloudapp.azure.com/api/metrics/core-attribute-measure-with-average",
          {
            llm: discoveryCtx.llm,
            products: [competitor],
            brand_category: category,
            persona: "General Profile",
          }
        )
      );
    });

    try {
      const responses = await Promise.all(promiseArray);
      const resData = responses.filter(res=> res).map((response) => response.data);

      let coreAttributeSummary = "";
      let category_averages = resData[0].category_averages;
      let products = [];
      const sources = [];
      for (let res of resData) {
        if (!res.hasOwnProperty("error")) {
          coreAttributeSummary += res.products[0]?.summary;
          coreAttributeSummary += "\n\n";
          products.push(res.products[0]);
          sources.push(
            ...Object.keys(res.sources.category),
            ...Object.keys(res.sources.product)
          );
        }
      }

      discoveryCtx.updateCoreAttributes({
        category_averages: category_averages,
        products: products,
        summary: coreAttributeSummary,
      });

      discoveryCtx.updateSources(sources);
    } catch (error) {
      console.log("Something went wrong ", error.message);
      setError(error.message);
    }
  }

  function formatName(str) {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  function renderRows(item, index) {
    return (
      <StyledTableRow key={index}>
        <StyledTableCell className={classes.attribute_headings}>
          {formatName(item.name)}
        </StyledTableCell>
        {item.scores.map((item, index) => {
          let color = "#dc3545";
          if (item.score >= 7) color = "#2196F3";
          if (item.score >= 9) color = "#198754";

          return (
            <Tooltip
              key={index}
              title={item.explanation}
              arrow
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -18],
                      },
                    },
                  ],
                },
              }}
            >
              <StyledTableCell
                style={{ color }}
                className={classes.attribute_score}
              >
                {item.score}
              </StyledTableCell>
            </Tooltip>
          );
        })}
      </StyledTableRow>
    );
  }

  function LoadingSkeletion() {
    return (
      <div className="p-3">
        <Skeleton variant="rectangular" height={30} />
        {Array(8)
          .fill(0)
          .map((_, index) => (
            <Skeleton
              variant="rectangular"
              width={index % 2 === 0 ? "80%" : "97%"}
              key={index}
              className="my-3"
              height={20}
            />
          ))}
      </div>
    );
  }

  useEffect(() => {
    if (
      discoveryCtx?.core_attributes &&
      Object.keys(discoveryCtx?.core_attributes).length > 0
    )
      return;
    getCoreAttributes();
  }, []);

  function renderHeaders(coreAttributesData) {
    const headers = [
      ...TABLE_HEADERS,
      ...discoveryCtx.competitors.map((item) => ({
        id: item.name,
        name: item.name,
      })),
    ];

    return (
      <TableHead className={classes.table_head}>
        <TableRow>
          {headers.map((header, index) => {
            let ctxCompetitor = null
            if(index !== 0) {
              ctxCompetitor = discoveryCtx.competitors[index-1];
            }

            let domain = "";
            if (ctxCompetitor && ctxCompetitor?.domain) {
              domain = extractDomain(ctxCompetitor?.domain);
            }

            return (
              <TableCell key={index} className={classes.table_head_cell}>
                <div className={classes.table_head_content}>
                  {index != 0 && (
                    <img
                      data-html2canvas-ignore
                      className={classes.table_head_logo}
                      src={`https://img.logo.dev/${domain}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
                    />
                  )}
                  <div
                    className={
                      index === 0
                        ? classes.table_head_text_comp
                        : classes.table_head_text
                    }
                  >
                    {header.name}
                  </div>
                </div>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  let coreAttributesData = [];
  let loading = true;

  if (
    discoveryCtx?.core_attributes &&
    Object.keys(discoveryCtx?.core_attributes).length > 0 &&
    discoveryCtx?.core_attributes?.summary
  ) {
    coreAttributesData = Object.keys(
      discoveryCtx.core_attributes.category_averages
    ).map((item) => {
      const scores = discoveryCtx.core_attributes.products.map((p) => ({
        name: p.name,
        score: p.attributes[item].score,
        explanation: p.attributes[item].explanation,
      }));
      return {
        name: item,
        scores,
      };
    });
    loading = false;
  }

  return (
    <MUICard variant="elevated" elevation={0} className={classes.card}>
      {coreAttributesData &&
        coreAttributesData.length > 0 &&
        !loading &&
        !error && (
          <Table
            elevation={0}
            data={coreAttributesData}
            renderHeaders={() => renderHeaders(coreAttributesData)}
            render={renderRows}
          />
        )}
      {loading && !error && <LoadingSkeletion />}
      {error && (
        <div className={classes.error}>
          <ErrorView
            title={"Error fetching core attributes"}
            message={error}
            retry
            onRetry={() => getCoreAttributes()}
          />
        </div>
      )}
    </MUICard>
  );
}
