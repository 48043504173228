import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import EmptyView from "../../../Component/EmptyView/EmptyView";

const ReMultibarChart = ({ data, index, isCompetitor }) => {
  // if (!data || data?.length == 0) {
  //   return;
  // }

  // Preprocess data to remove null values and avoid empty spaces in bars
  const filteredData = data?.map((item) => {
    const newItem = { ...item };
    Object.keys(newItem).forEach((key) => {
      if (newItem[key] === null || newItem[key] === undefined) {
        delete newItem[key];
      }
    });
    return newItem;
  });

  // Get all unique keys from the data array
  const allKeys = Array.from(
    new Set(
      filteredData?.flatMap((item) =>
        Object.keys(item).filter((key) => key !== "name")
      )
    )
  );

  const compLabelsMapping = {
    0: "Positive Attributes",
    1: "Negative Attributes",
  };

  const colors = [
    "#C7F0EC",
    "#FF6F6F",
    "#CEB4FF",
    "#FFCE6E",
    "#92B7FE",
    "#FEE7E6",
    "#CEB4FF",
    "#FFCE6E",
  ];

  const barSize = 30; // Fixed width for each bar
  const margin = { top: 20, right: 30, left: 20, bottom: 5 };

  // Calculate the chart width based on the number of keys (brands) and attributes
  const chartWidth = allKeys.length * barSize + margin.left + margin.right;

  return (
    <>
      <h5 className="me-3">{compLabelsMapping[index]}</h5>
      {filteredData && filteredData?.length ? (
        <ResponsiveContainer
          width="100%"
          height={450}
          style={{ backgroundColor: "white" }}
        >
          <BarChart
            data={filteredData}
            width={chartWidth} // Dynamically calculated width
            height={300}
            //   data={data}
            margin={margin}
            barCategoryGap="20%" // Adjust the gap between bars in a category
          >
            <CartesianGrid strokeDasharray="3 3" />
            <Legend
              verticalAlign="top"
              align="center"
              wrapperStyle={{ paddingBottom: 20 }} // Adjust padding to create space between legend and bars
            />
            <XAxis
              dataKey="name"
              tickLine={false}
              tick={{
                fill: "#b6b7b8",
                fontFamily: "Roboto Flex, sans-serif",
                fontSize: 14,
                width: 80, // Set a fixed width for the labels
                wordWrap: "break-word", // Ensure text wraps if it exceeds the width
              }}
              interval={0} // Force all labels to display
              height={60} // Increase height to ensure labels are visible
            />
            <YAxis
              tickLine={false}
              tick={{
                fill: "#b6b7b8",
                fontFamily: "Roboto Flex, sans-serif",
                fontSize: 14,
              }}
              axisLine={{ stroke: "white" }}
              padding={{ top: 20, bottom: 10 }} // Increase top padding for more space
              domain={[0, 100]} // Limit Y-axis from 0 to 100
              tickCount={11} // Y-axis ticks will be at every 10 units
            />
            <Tooltip />

            {allKeys.map((brand, index) => (
              <Bar
                key={brand}
                dataKey={brand}
                fill={
                  colors?.[index] ||
                  `#${Math.floor(Math.random() * 16777215).toString(16)}`
                } // Random color
                barSize={barSize} // Fixed bar width for better visibility
                isAnimationActive={true}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <EmptyView message="Data Not Available" />
      )}
    </>
  );
};

export default ReMultibarChart;
