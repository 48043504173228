import { useContext, useState } from "react";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

import classes from "./HistoryItem.module.css";
import MUIIconButton from "../../../Component/UI/MUIIconButton/MUIIconButton";
import { DiscoveryContext } from "../discovery-context";
import Modal from "../../../Component/Modal/Modal";
import {
  deleteDataFromFirebase,
  updateDataInFirebase,
} from "./../../../DatabaseFirebase/firebaseService";

export default function HistoryItem({ item }) {
  const [name, setName] = useState(item?.name || item.entity);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const discoveryCtx = useContext(DiscoveryContext);

  function deleteHistoryItemHandler() {
    setShowDeleteDialog(false);
    discoveryCtx.resetState();
    deleteDataFromFirebase(item.id, "discovery_history");
  }

  function handleDoneEditing() {
    setIsEditing(false);
    if (item.entity.toString().trim() == name.toString().trim()) {
      return;
    }
    updateDataInFirebase("discovery_history", item.id, {
      ...item,
      name,
    });
  }

  return (
    <div className={classes.container}>
      <Modal
        handleClose={() => setShowDeleteDialog(false)}
        handleConfirm={deleteHistoryItemHandler}
        open={showDeleteDialog}
        title="Delete History?"
        showActions
        confirmBtnText="Delete"
        confirmBtnColor="error"
      >
        Do you want to delete this item - {name}?
      </Modal>
      <div className={classes.text_container}>
        <TextsmsOutlinedIcon />
        {!isEditing && <span className={classes.text}>{name}</span>}
        {isEditing && (
          <input
            className={classes.input}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        )}
      </div>
      <div className={classes.action}>
        {!isEditing && (
          <>
            <MUIIconButton
              sx={{ color: "#000" }}
              onClick={() => {
                let coreAttributeSummary = item?.core_attributes?.summary ?? "";
                if (!coreAttributeSummary) {
                  item?.core_attributes &&
                    item?.core_attributes?.products &&
                    item?.core_attributes?.products.map((product) => {
                      coreAttributeSummary += product.summary;
                      coreAttributeSummary += "\n\n";
                    });
                }
                discoveryCtx.updateEntireState({
                  ...item,
                  core_attributes: {
                    ...item.core_attributes,
                    summary: coreAttributeSummary,
                  },
                  is_launched: true,
                });
              }}
            >
              <VisibilityOutlinedIcon />
            </MUIIconButton>
            <MUIIconButton
              sx={{ color: "#000" }}
              onClick={() => setIsEditing(true)}
            >
              <DriveFileRenameOutlineOutlinedIcon />
            </MUIIconButton>
            <MUIIconButton
              sx={{ color: "#000" }}
              onClick={() => setShowDeleteDialog(true)}
            >
              <DeleteOutlineOutlinedIcon />
            </MUIIconButton>
          </>
        )}
        {isEditing && (
          <MUIIconButton onClick={handleDoneEditing}>
            <DoneOutlinedIcon />
          </MUIIconButton>
        )}
      </div>
    </div>
  );
}
