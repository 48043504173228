import { useEffect, useContext, useState } from "react";
import axios from "axios";
import { Skeleton } from "@mui/material";

import classes from "./Description.module.css";
import { DiscoveryContext } from "../discovery-context";
import ErrorMessage from './../../../Component/ErrorMessage/ErrorMessage'

export default function Description({ entity }) {
  const [error, setError] = useState();

  const discoveryCtx = useContext(DiscoveryContext);

  async function getBrandDescription() {
    setError(null);
    const payload = {
      llm: discoveryCtx.llm,
      prompt: `Provide a 50-word brand description for ${entity}. Respond only with the description, without any introductory phrases or qualifiers`,
    };

    try {
      let response = await axios.default.post(
        `https://brandluminaire.eastus.cloudapp.azure.com/api/llm-execute`,
        payload
      );
      discoveryCtx.updateBrandDescription(response.data.data);
    } catch (error) {
      console.log("Something went wrong", error);
      setError(error.message);
    }
  }

  useEffect(() => {
    if (discoveryCtx?.brand_description.length > 0) return;
    getBrandDescription(entity);
  }, [entity, discoveryCtx?.brand_description.length]);

  let brandDescription = null;
  let loading = true;
  if (discoveryCtx?.brand_description.length > 0) {
    brandDescription = discoveryCtx.brand_description;
    loading = false;
  }

  return (
    <div className={classes.text}>
      {loading && !error && (
        <>
          <Skeleton />
          <Skeleton style={{ width: "50%" }} />
        </>
      )}
      {!error && !loading && brandDescription}
      {error && <ErrorMessage message={error} retry align="left" onRetry={() => getBrandDescription()} />}
    </div>
  );
}
