import React, { useEffect, useState } from "react";
import ReLineChart from "../common/ReLinechart";
import ReBarChart from "../common/ReBarchart";
import RadialGraph from "../common/ReRadialGraph";
import AiIcon from "../../../assets/svgs/AIIcon";
import {
  coreAttributesWithDeltaScores,
  getAttDataWithDelta,
  getSummaryPrompt,
  restructureCoreAttData,
  restructureCategoryDimData,
  getFilteredPurchaseDimensions,
} from "../../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import classes from "./DashboardComponent.module.css";
import { fetchGpt4API, fetchGeminiAPI } from "../../../services/apiService";
import Spinner from "../../../Component/Spinner/Spinner";
import EmptyView from "../../../Component/EmptyView/EmptyView";

const OverviewComponent = ({
  positiveAttGraphData,
  negativeAttGraphData,
  pastPositiveAttGraphData,
  pastNegativeAttGraphData,
  // brandIndexScore,
  // pastBrandIndexScore,
  brandCoreAttributesScore,
  compCoreAttributes,
  pastBrandCoreAttributesScore,
  pastCompCoreAttributes,
  brandCategoryDimScore,
  compCategoryDimensions,
  pastCompCategoryDimensions,
  pastBrandCategoryDimScore,
  indexScoreDataForLineChart,
  frequency,
  brandProduct,
  brandProductCategory,
  compBrandIndexScore,
  pastCompBrandIndexScore,
}) => {
  const filteredPurchaseDimensions = getFilteredPurchaseDimensions(
    compCategoryDimensions
  );

  const currentFrequencyMapping = {
    Current: "today's",
    "3Days": "current 3 days average",
    "7Days": "current 7 days average",
    Month: "current 30 days average",
  };

  const priorFrequencyMapping = {
    Current: "yesterday's",
    "3Days": "prior 3 days average",
    "7Days": "prior 7 days average",
    Month: "prior 30 days average",
  };

  const getBrandIndexPromptData = (property, label) => {
    return {
      [`${currentFrequencyMapping?.[frequency] || ""} ${property}`]: {
        ...brandIndexScore?.[label],
        category: brandProductCategory,
      },
      [`${priorFrequencyMapping?.[frequency] || ""} ${property}`]: {
        ...pastBrandIndexScore?.[label],
        category: brandProductCategory,
      },
    };
  };

  const getBrandIndexSummary = (data, mainDataKey, promptKey, summaryKey) => {
    if (data?.[mainDataKey]) {
      const data = getBrandIndexPromptData(promptKey, mainDataKey);
      fetchSummaryData(data, summaryKey);
    }
  };

  const filterDataByBrand = (data, brand) => {
    const filteredData = {};

    for (let property in data) {
      if (data[property].hasOwnProperty(brand)) {
        // Create a new object with brand and its score for each property
        filteredData[property] = { [brand]: data[property][brand] };
      }
    }

    return filteredData;
  };

  const brandIndexScore = filterDataByBrand(compBrandIndexScore, brandProduct);
  const pastBrandIndexScore = filterDataByBrand(
    pastCompBrandIndexScore,
    brandProduct
  );

  const transformGraphData = (graphData) => {
    return (
      graphData?.map((obj) => ({
        "attribute-category": obj?.name,
        "attribute-category-signal-strength": obj?.value,
        attributes: obj?.altNames,
        totalSignlStrengths: obj?.signalStrengths,
      })) || []
    );
  };

  // Function to handle fetching summary data for both positive and negative attributes
  const fetchAttributeData = (
    currentGraphData,
    pastGraphData,
    currentFrequencyMapping,
    priorFrequencyMapping,
    brandProduct,
    type,
    frequency,
    fetchSummaryData,
    attributeType
  ) => {
    const currentData = transformGraphData(currentGraphData);

    const priorData = transformGraphData(pastGraphData);

    const finalData = {
      [`${
        currentFrequencyMapping[frequency || ""]
      } ${type} attributes of ${brandProduct}`]: currentData,
      [`${
        priorFrequencyMapping[frequency || ""]
      } ${type} attributes of ${brandProduct}`]: priorData,
    };
    fetchSummaryData(finalData, attributeType);
  };

  const [summaries, setSummaries] = useState({
    positiveAttributes: null,
    negativeAttributes: null,
    indexScore: null,
    favorability: null,
    rank: null,
  });

  const [loading, setLoading] = useState({
    positiveAttributes: null,
    negativeAttributes: null,
    indexScore: null,
    favorability: null,
    rank: null,
  });

  const [errors, setErrors] = useState({
    positiveAttributes: null,
    negativeAttributes: null,
    indexScore: null,
    favorability: null,
    rank: null,
  });

  const [abortControllers, setAbortControllers] = useState([]);

  // Helper to create a new AbortController
  const createAbortController = () => {
    const abortController = new AbortController();
    setAbortControllers((prevControllers) => [
      ...prevControllers,
      abortController,
    ]);
    return abortController;
  };

  // Helper to reset and abort any existing API calls
  const resetAbortControllers = () => {
    abortControllers.forEach((controller) => controller.abort());
    setAbortControllers([]);
  };

  // Function to fetch data for a given prop
  const fetchSummaryData = async (dataProp, propKey) => {
    setLoading((prev) => ({ ...prev, [propKey]: true }));
    setErrors((prev) => ({ ...prev, [propKey]: null }));

    const compReachScoreData = compBrandIndexScore?.Rank;

    try {
      const controller = createAbortController();
      const prompt = getSummaryPrompt(dataProp, propKey, compReachScoreData);
      let result = await fetchGeminiAPI(
        prompt,
        controller,
        undefined,
        undefined,
        "gpt-4o"
      );
      result = result["Gemini"][0];
      setSummaries((prev) => ({ ...prev, [propKey]: result }));
    } catch (err) {
      setErrors((prev) => ({
        ...prev,
        [propKey]: "Failed to generate summary",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, [propKey]: false }));
    }
  };

  const getAttributesSummary = (type) => {
    // Fetch data for each prop simultaneously
    if (
      type == "positive-attributes" &&
      positiveAttGraphData &&
      positiveAttGraphData.length
    ) {
      fetchAttributeData(
        positiveAttGraphData,
        pastPositiveAttGraphData,
        currentFrequencyMapping,
        priorFrequencyMapping,
        brandProduct,
        "positive",
        frequency,
        fetchSummaryData,
        "positiveAttributes"
      );
    }

    if (
      type == "negative-attributes" &&
      negativeAttGraphData &&
      negativeAttGraphData.length
    ) {
      fetchAttributeData(
        negativeAttGraphData,
        pastNegativeAttGraphData,
        currentFrequencyMapping,
        priorFrequencyMapping,
        brandProduct,
        "negative",
        frequency,
        fetchSummaryData,
        "negativeAttributes"
      );
    }
  };

  useEffect(() => {
    // Reset existing abort controllers before making new API calls
    resetAbortControllers();

    getBrandIndexSummary(
      brandIndexScore,
      "Brand Index Score",
      "Brand Index Score",
      "indexScore"
    );
  }, [compBrandIndexScore]); // Re-fetch data if any prop changes

  const coreAttributesData = restructureCoreAttData(
    compCoreAttributes,
    brandCoreAttributesScore
  );

  const pastCoreAttributesData = restructureCoreAttData(
    pastCompCoreAttributes,
    pastBrandCoreAttributesScore
  );

  let categoryDimData = restructureCategoryDimData(
    filteredPurchaseDimensions,
    brandCategoryDimScore
  );

  categoryDimData = categoryDimData.slice(0, 5);

  const pastCategoryDimData = restructureCategoryDimData(
    pastCompCategoryDimensions,
    pastBrandCategoryDimScore
  );

  const coreAttributesWithDelta = coreAttributesWithDeltaScores(
    coreAttributesData,
    pastCoreAttributesData
  );

  const categoryDimsWithDelta = coreAttributesWithDeltaScores(
    categoryDimData,
    pastCategoryDimData
  );

  const dateMapping = {
    Current: "Current",
    "3Days": "Last 3 Days",
    "7Days": "Last 7 Days",
    Month: "Last 30 Days",
    "3Month": "Last 90 Days",
  };

  return (
    <div>
      <div className="col-md-12 mt-4">
        <div className="card p-3 custCard">
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <h5 className="me-3">Brand Index Score - {brandProductCategory}</h5>
          </div>
          <div className="row">
            <div className="col-md-4">
              {brandIndexScore?.["Brand Index Score"] ? (
                <div className="graphGreyBg">
                  <div className="d-flex align-items-center flex-column mb-3">
                    <RadialGraph
                      label={`Brand Index Score-${brandProductCategory}`}
                      value={brandIndexScore?.["Brand Index Score"]}
                      pastValue={pastBrandIndexScore?.["Brand Index Score"]}
                      Range={100}
                      frequencyLabel={dateMapping[frequency]}
                    />
                  </div>
                  <div className="graphsummary2">
                    <p className="mb-2">
                      Summary
                      <button className="mb-1 ms-2 btn-purple text-white">
                        <AiIcon />
                        AI Generated
                      </button>
                    </p>
                    {loading?.indexScore ? (
                      <Spinner />
                    ) : summaries?.indexScore ? (
                      <p className="font-10 darker-grey summaryList2">
                        {summaries?.indexScore}
                      </p>
                    ) : errors?.indexScore ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        {errors?.indexScore}
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : (
                <EmptyView message="Data Not Available" />
              )}
            </div>
            <div className="col-md-8">
              {indexScoreDataForLineChart &&
              indexScoreDataForLineChart.length ? (
                <ReLineChart data={indexScoreDataForLineChart} />
              ) : (
                <EmptyView message="Competitor Data Not Available" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mt-4">
          <div className="card p-3 custCard">
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <h5 className="me-3">Reach Score - {brandProductCategory}</h5>
            </div>
            {brandIndexScore?.["Rank"] ? (
              <div>
                <div className="d-flex align-items-center flex-column mb-3">
                  <RadialGraph
                    label="Brand Reach Score"
                    value={brandIndexScore?.["Rank"]}
                    pastValue={pastBrandIndexScore?.["Rank"]}
                    Range={50}
                    frequencyLabel={dateMapping[frequency]}
                  />
                </div>
                <div className="graphsummary2 d-flex align-items-center">
                  <div className="minW-140">
                    <p className="mb-1">Summary</p>
                    <button
                      onClick={() => {
                        getBrandIndexSummary(
                          brandIndexScore,
                          "Rank",
                          "Brand Reach/Rank Score",
                          "rank"
                        );
                      }}
                      className="mb-1 btn-purple text-white"
                    >
                      <AiIcon />
                      AI Generate
                    </button>
                  </div>
                  {loading?.rank ? (
                    <Spinner />
                  ) : summaries?.rank ? (
                    <p className="font-10 darker-grey summaryList2">
                      {summaries?.rank}
                    </p>
                  ) : errors?.rank ? (
                    <p style={{ color: "red", textAlign: "center" }}>
                      {errors?.rank}
                    </p>
                  ) : null}
                </div>
              </div>
            ) : (
              <EmptyView message="Data Not Available" />
            )}
          </div>
        </div>
        <div className="col-md-6 mt-4">
          <div className="card p-3 custCard">
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <h5 className="me-3">Sentiment Score - {brandProductCategory}</h5>
            </div>
            {brandIndexScore?.["Favorability"] ? (
              <div className="">
                <div className="d-flex align-items-center flex-column mb-3">
                  <RadialGraph
                    label="Brand Favorability Score"
                    value={brandIndexScore?.["Favorability"]}
                    pastValue={pastBrandIndexScore?.["Favorability"]}
                    Range={50}
                    delta={
                      Number(brandIndexScore?.["Favorability"]) -
                      Number(pastBrandIndexScore?.["Favorability"])
                    }
                    frequencyLabel={dateMapping[frequency]}
                  />
                </div>
                <div className="graphsummary2 d-flex align-items-center">
                  <div className="minW-140">
                    <p className="mb-1">Summary</p>
                    <button
                      onClick={() => {
                        getBrandIndexSummary(
                          brandIndexScore,
                          "Favorability",
                          "Brand Favorability Score",
                          "favorability"
                        );
                      }}
                      className="mb-1 btn-purple text-white"
                    >
                      <AiIcon />
                      AI Generate
                    </button>
                  </div>
                  {loading?.favorability ? (
                    <Spinner />
                  ) : summaries?.favorability ? (
                    <p className="font-10 darker-grey summaryList2">
                      {summaries?.favorability}
                    </p>
                  ) : errors?.favorability ? (
                    <p style={{ color: "red", textAlign: "center" }}>
                      {errors?.favorability}
                    </p>
                  ) : null}
                </div>
              </div>
            ) : (
              <EmptyView message="Data Not Available" />
            )}
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <div className="card p-3 custCard">
            <div className="row">
              <div className="col-md-8">
                <div className="mb-3 d-flex justify-content-between align-items-center">
                  <h5 className="me-3">Positive Attributes</h5>

                  <div className="btnGroup">
                    <button className="toggleBtn">
                      Table <i className="ms-2 feather icon-grid"></i>
                    </button>
                    &nbsp; &nbsp;
                    <button className="toggleBtn active">
                      Graph <i className="ms-2 feather icon-bar-chart-2"></i>
                    </button>
                  </div>
                </div>
                {positiveAttGraphData && positiveAttGraphData.length ? (
                  <div>
                    <ReBarChart
                      data={
                        getAttDataWithDelta(
                          positiveAttGraphData,
                          pastPositiveAttGraphData
                        ) || []
                      }
                      color="#C7F0EC"
                    />
                  </div>
                ) : (
                  <EmptyView message="Data Not Available" />
                )}
              </div>
              <div className="col-md-4">
                <div className="graphGreyBg">
                  <p className="mb-3 font14-600">
                    Summary
                    <button
                      onClick={() => {
                        getAttributesSummary("positive-attributes");
                      }}
                      className="btn-purple ms-2 text-white font-10"
                    >
                      <AiIcon />
                      AI Generate
                    </button>
                  </p>
                  <div className="graphsummary font-11">
                    {loading?.positiveAttributes ? (
                      <Spinner />
                    ) : summaries?.positiveAttributes ? (
                      <p className="mb-1"> {summaries?.positiveAttributes} </p>
                    ) : errors?.positiveAttributes ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        {errors?.positiveAttributes}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <div className="card p-3 custCard">
            <div className="row">
              <div className="col-md-8">
                <div className="mb-3 d-flex justify-content-between align-items-center">
                  <h5 className="me-3">Negative Attributes</h5>
                  {/* <div class="mwqGroup">
                    <button>Monthly</button>
                    <button>Weekly</button>
                    <button class="active">Quarterly</button>
                  </div> */}
                  <div className="btnGroup">
                    <button className="toggleBtn">
                      Table <i className="ms-2 feather icon-grid"></i>
                    </button>
                    &nbsp; &nbsp;
                    <button className="toggleBtn active">
                      Graph <i className="ms-2 feather icon-bar-chart-2"></i>
                    </button>
                  </div>
                </div>
                {negativeAttGraphData && negativeAttGraphData.length ? (
                  <div>
                    <ReBarChart
                      data={
                        getAttDataWithDelta(
                          negativeAttGraphData,
                          pastNegativeAttGraphData
                        ) || []
                      }
                      color="#FEE7E6"
                    />
                  </div>
                ) : (
                  <EmptyView message="Data Not Available" />
                )}
              </div>
              <div className="col-md-4">
                <div className="graphGreyBg">
                  <p className="mb-3 font14-600">
                    Summary
                    <button
                      onClick={() => {
                        getAttributesSummary("negative-attributes");
                      }}
                      className="btn-purple ms-2 text-white font-10"
                    >
                      <AiIcon />
                      AI Generate
                    </button>
                  </p>
                  <div className="graphsummary font-11">
                    {loading?.negativeAttributes ? (
                      <Spinner />
                    ) : summaries?.negativeAttributes ? (
                      <p className="mb-1"> {summaries?.negativeAttributes} </p>
                    ) : errors?.positiveAttributes ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        {errors?.positiveAttributes}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-4">
          <div className="card p-3 custCard">
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <h5 className="me-3">Core Attributes</h5>
            </div>

            <div className="custGrid">
              <div className="gridHeading mb-2">
                <div className="headingTitle">Core Attributes</div>
                <div className="headingTitle">Brand</div>
                <div className="headingTitle">Competitor Avg</div>
              </div>
              {coreAttributesWithDelta && coreAttributesWithDelta.length ? (
                coreAttributesWithDelta?.map((obj) => {
                  const score = Number(obj?.mainAvgScore || 0).toFixed(0);
                  const compScore = Number(obj?.avgScore || 0).toFixed(0);
                  let color = "#dc3545";
                  let compColor = "#dc3545";
                  if (score >= 7) color = "#2196F3";
                  if (score >= 9) color = "#198754";
                  if (compScore >= 7) compColor = "#2196F3";
                  if (compScore >= 9) compColor = "#198754";
                  return (
                    <div className="gridContent text-center">
                      <div className="content">{obj?.attribute}</div>
                      <div className="content">
                        <div className="d-flex align-items-center justify-content-center flex-row">
                          {score <= 0 ? (
                            "NA"
                          ) : (
                            <>
                              <span
                                style={{ color: color }}
                                className={`h5 mb-0 me-1  ${classes["core-main-value"]}`}
                              >
                                {score}
                              </span>
                              {obj?.deltaMainAvgScore && (
                                <>
                                  <FontAwesomeIcon
                                    className={
                                      obj?.deltaMainAvgScore < 0
                                        ? `${classes["core-down-icon"]} text-danger me-1`
                                        : `${classes["core-up-icon"]} text-success me-1`
                                    }
                                    icon={
                                      obj?.deltaMainAvgScore < 0
                                        ? faSortDown
                                        : faSortUp
                                    }
                                  />
                                  <span className={classes.coreDelta}>
                                    {obj?.deltaMainAvgScore
                                      ? obj?.deltaMainAvgScore > 0
                                        ? `${Number(
                                            Math.abs(obj?.deltaMainAvgScore) ||
                                              0
                                          ).toFixed(0)}`
                                        : Number(
                                            Math.abs(obj?.deltaMainAvgScore) ||
                                              0
                                          ).toFixed(0)
                                      : "NA"}
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="content">
                        <div className="d-flex align-items-center justify-content-center flex-row">
                          {compScore <= 0 ? (
                            "NA"
                          ) : (
                            <>
                              <span
                                style={{ color: compColor }}
                                className={`h5 mb-0 me-1  ${classes["core-main-value"]}`}
                              >
                                {compScore}
                              </span>
                              {obj?.deltaAvgScore && (
                                <>
                                  <FontAwesomeIcon
                                    className={
                                      obj?.deltaAvgScore < 0
                                        ? `${classes["core-down-icon"]} text-danger me-1`
                                        : `${classes["core-up-icon"]} text-success me-1`
                                    }
                                    icon={
                                      obj?.deltaAvgScore < 0
                                        ? faSortDown
                                        : faSortUp
                                    }
                                  />
                                  <span className={classes.coreDelta}>
                                    {obj?.deltaAvgScore
                                      ? obj?.deltaAvgScore > 0
                                        ? `${Number(
                                            Math.abs(obj?.deltaAvgScore) || 0
                                          ).toFixed(0)}`
                                        : Number(
                                            Math.abs(obj?.deltaAvgScore) || 0
                                          ).toFixed(0)
                                      : "NA"}
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <EmptyView message="Data Not Available" />
              )}
            </div>
          </div>
        </div>

        <div className="col-md-6 mt-4">
          <div className="card p-3 custCard">
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <h5 className="me-3">Purchase Criteria</h5>
            </div>

            <div className="custGrid">
              <div className="gridHeading mb-2">
                <div className="headingTitle">Purchase Dimensions</div>
                <div className="headingTitle">Brand</div>
                <div className="headingTitle">Competitor Avg</div>
              </div>
              {categoryDimsWithDelta && categoryDimsWithDelta.length ? (
                categoryDimsWithDelta?.map((obj) => {
                  const score = Number(obj?.mainAvgScore || 0).toFixed(0);
                  const compScore = Number(obj?.avgScore || 0).toFixed(0);

                  let color = "#dc3545";
                  let compColor = "#dc3545";
                  if (score >= 7) color = "#2196F3";
                  if (score >= 9) color = "#198754";
                  if (compScore >= 7) compColor = "#2196F3";
                  if (compScore >= 9) compColor = "#198754";
                  return (
                    <div className="gridContent text-center">
                      <div className="content">{obj?.attribute}</div>
                      <div className="content">
                        <div className="d-flex align-items-center justify-content-center flex-row">
                          {score <= 0 ? (
                            "NA"
                          ) : (
                            <>
                              <span
                                style={{ color: color }}
                                className={`h5 mb-0 me-1  ${classes["core-main-value"]}`}
                              >
                                {score}
                              </span>
                              {obj?.deltaMainAvgScore && (
                                <>
                                  <FontAwesomeIcon
                                    className={
                                      obj?.deltaMainAvgScore < 0
                                        ? `${classes["core-down-icon"]} text-danger me-1`
                                        : `${classes["core-up-icon"]} text-success me-1`
                                    }
                                    icon={
                                      obj?.deltaMainAvgScore < 0
                                        ? faSortDown
                                        : faSortUp
                                    }
                                  />
                                  <span className={classes.coreDelta}>
                                    {obj?.deltaMainAvgScore
                                      ? obj?.deltaMainAvgScore > 0
                                        ? `${Number(
                                            Math.abs(obj?.deltaMainAvgScore) ||
                                              0
                                          ).toFixed(0)}`
                                        : Number(
                                            Math.abs(obj?.deltaMainAvgScore) ||
                                              0
                                          ).toFixed(0)
                                      : "NA"}
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="content">
                        <div className="d-flex align-items-center justify-content-center flex-row">
                          {compScore <= 0 ? (
                            "NA"
                          ) : (
                            <>
                              <span
                                style={{ color: compColor }}
                                className={`h5 mb-0 me-1  ${classes["core-main-value"]}`}
                              >
                                {compScore}
                              </span>
                              {obj?.deltaAvgScore && (
                                <>
                                  <FontAwesomeIcon
                                    className={
                                      obj?.deltaAvgScore < 0
                                        ? `${classes["core-down-icon"]} text-danger me-1`
                                        : `${classes["core-up-icon"]} text-success me-1`
                                    }
                                    icon={
                                      obj?.deltaAvgScore < 0
                                        ? faSortDown
                                        : faSortUp
                                    }
                                  />
                                  <span className={classes.coreDelta}>
                                    {obj?.deltaAvgScore
                                      ? obj?.deltaAvgScore > 0
                                        ? `${Number(
                                            Math.abs(obj?.deltaAvgScore) || 0
                                          ).toFixed(0)}`
                                        : Number(
                                            Math.abs(obj?.deltaAvgScore) || 0
                                          ).toFixed(0)
                                      : "NA"}
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <EmptyView message="Data Not Available" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewComponent;
