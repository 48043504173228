import React from "react";
import { Outlet } from "react-router";

import Layout from "./Layout";
import DiscoveryProvider from "./Discovery/discovery-context";

const ContentLayout = () => {
  return (
    <DiscoveryProvider>
      <Layout>
        <Outlet />
      </Layout>
    </DiscoveryProvider>
  );
};

export default ContentLayout;
