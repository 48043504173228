import { useContext, useEffect, useState } from "react";

import { AuthContext } from "../context/auth-context";
import {
  addDataToFirebase,
  updateDataInFirebase,
} from "../DatabaseFirebase/firebaseService";
import axios from "axios";

function isSameDate(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export function useDailyLimits(feature, limit = 10) {
  const [apiCalls, setApiCalls] = useState(0);
  const [remainingCalls, setRemainingCalls] = useState(0);
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(false);

  const { isCurrentUserAdmin, authUserEmail } = useContext(AuthContext);

  const apiCallLimitToCurrentUser = limit; //isCurrentUserAdmin ? 100 : 10;

  const getHistory = async () => {
    const FirebaseRealtimeDBURL =
      "https://brand-luminaire-default-rtdb.firebaseio.com";

    const data = await axios.get(`${FirebaseRealtimeDBURL}/Launches.json`);
    setLoading(false);
    if (data.data == null) {
      let user = {
        email: authUserEmail,
        apiCalls: apiCallLimitToCurrentUser,
        remainingCalls: apiCallLimitToCurrentUser,
        feature: feature,
        date: Date.now(),
      };
      if (data.data == null) {
        setCurrentUser(user);
        setRemainingCalls(user.remainingCalls);
        setApiCalls(user.apiCalls);
      }
    } else {
      const allUsers = [];
      for (let key in data.data) {
        allUsers.push({ id: key, ...data.data[key] });
      }
      const fbUser = allUsers.find(
        (user) => user.email === authUserEmail && user.feature == feature
      );
      if (fbUser) {
        const currentDate = Date.now();
        const fbDate = fbUser.date;
        const callsRemaining = isSameDate(currentDate, fbDate)
          ? fbUser.remainingCalls
          : fbUser.apiCalls;
        setCurrentUser(fbUser);
        setApiCalls(fbUser.apiCalls);
        setRemainingCalls(callsRemaining);
      } else {
        let user = {
          email: authUserEmail,
          apiCalls: apiCallLimitToCurrentUser,
          remainingCalls: apiCallLimitToCurrentUser,
          feature: feature,
          date: Date.now(),
        };
        addDataToFirebase("Launches", user);
        setCurrentUser(user);
        setApiCalls(user.apiCalls);
        setRemainingCalls(user.remainingCalls);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    getHistory();
  }, []);

  async function updateLaunches() {
    if (!currentUser.id) {
      let updatedUser = {
        ...currentUser,
        remainingCalls: currentUser.remainingCalls - 1,
      };
      const { key } = await addDataToFirebase("Launches", updatedUser);
      setRemainingCalls(currentUser.remainingCalls - 1);
      setCurrentUser((user) => {
        return {
          ...user,
          apiCalls: apiCallLimitToCurrentUser,
          date: Date.now(),
          id: key,
        };
      });
    } else {
      const updatedCount = remainingCalls - 1;
      updateDataInFirebase("Launches", currentUser?.id, {
        ...currentUser,
        date: Date.now(),
        apiCalls: apiCallLimitToCurrentUser,
        remainingCalls: updatedCount,
      });
      setRemainingCalls(updatedCount);
    }
  }

  let limitReached = remainingCalls == 0;

  let launchText = `${remainingCalls} launch${
    remainingCalls !== 1 ? "es" : ""
  } remaining out of ${apiCallLimitToCurrentUser} daily limit.`;

  if (limitReached) {
    launchText = `You have reached your daily limit. Please try again tomorrow.`;
  }

  if (loading) launchText = "";

  return {
    launchesRemaining: remainingCalls,
    launchText: launchText,
    updateLaunches,
  };
}
